import React from 'react';
import './style.css';
const Banner = ()=>{
    return(
        <div className="banner">
            <div className="banner__call">
                <h1>Come to the dark side</h1>
                <button>Got it!</button>
            </div>
        </div>
    )
} 

export default Banner;
